<template>
    <button class="btn btn-secondary" role="button" type="submit">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="process" :disabled="process"></span>
        <slot></slot>
    </button>
</template>

<script>

export default {

    data() {
        return {
            process: false
          
        }
    },
    methods: {
        start(){
            this.process = true;
        },
        stop() {
            this.process = false;
        }
       
    },
}
</script>
