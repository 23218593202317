<template>
    <div v-if="live">
        <h3 class="mb-2 mb-sm-3 text-center">Идет подготовка к прямой трансляции розыгрыша...</h3>
    </div>
    <div v-else>
        <h3 class="mb-2 mb-sm-3 text-center">До следующего розыгрыша осталось:</h3>
        <div class="container-fluid px-0 px-sm-1">
            <div class="d-flex justify-content-between text-center">
                <div>
                    <div class="d-flex justify-content-evenly mx-1 mx-sm-3">
                        <div class="d-flex align-self-center fs-1 bg-primary bg-gradient text-white text-center mr-1 px-2 mr-sm-2 px-sm-2 mr-md-3 px-md-3"><span class="counter-time" v-html="d1"></span></div>
                        <div class="d-flex align-self-center fs-1 bg-primary bg-gradient text-white text-center ml-1 px-2 ml-sm-2 px-sm-2 ml-md-3 px-md-3"><span class="counter-time" v-html="d2"></span></div>
                    </div>
                    <span>дней</span>
                </div>
                <div>
                    <div class="d-flex justify-content-center mx-1 mx-sm-3">
                        <div class="d-flex align-self-center fs-1 bg-primary bg-gradient text-white text-center mr-1 px-2 mr-sm-2 px-sm-2 mr-md-3 px-md-3"><span class="counter-time" v-html="h1"></span></div>
                        <div class="d-flex align-self-center fs-1 bg-primary bg-gradient text-white text-center ml-1 px-2 ml-sm-2 px-sm-2 ml-md-3 px-md-3"><span class="counter-time" v-html="h2"></span></div>
                    </div>
                    <span>часов</span>
                </div>
                <div>
                    <div class="d-flex justify-content-center mx-1 mx-sm-3">
                        <div class="d-flex align-self-center fs-1 bg-primary bg-gradient text-white text-center mr-1 px-2 mr-sm-2 px-sm-2 mr-md-3 px-md-3"><span class="counter-time" v-html="m1"></span></div>
                        <div class="d-flex align-self-center fs-1 bg-primary bg-gradient text-white text-center ml-1 px-2 ml-sm-2 px-sm-2 ml-md-3 px-md-3"><span class="counter-time" v-html="m2"></span></div>
                    </div>
                    <span>минут</span>
                </div>
                <div>
                    <div class="d-flex justify-content-center mx-1 mx-sm-3">
                        <div class="d-flex align-self-center fs-1 bg-primary bg-gradient text-white text-center mr-1 px-2 mr-sm-2 px-sm-2 mr-md-3 px-md-3"><span class="counter-time" v-html="s1"></span></div>
                        <div class="d-flex align-self-center fs-1 bg-primary bg-gradient text-white text-center ml-1 px-2 ml-sm-2 px-sm-2 ml-md-3 px-md-3"><span class="counter-time" v-html="s2"></span></div>
                    </div>
                    <span>секунд</span>
                </div>
            </div>
            <!--<div class="d-flex justify-content-between">
            <div class="d-flex align-self-center mx-1 mx-sm-3">
                <span>дней</span>
            </div>
            <div class="d-flex justify-content-center mx-1 mx-sm-3">
                <span>дней</span>
            </div>
            <div class="d-flex justify-content-center mx-1 mx-sm-3">
                <span>дней</span>
            </div>
            <div class="d-flex justify-content-center mx-1 mx-sm-3">
                <span>дней</span>
            </div>
        </div>-->
            <!--<div  class="d-flex justify-content-around">
            <div>дней</div>
            <div>часов</div>
            <div>минут</div>
            <div>секунд</div>
        </div>-->
        </div>
    </div>
</template>

<style>
    .ch {
        min-height: 100px;
    }
    @media (max-width: 768px) {
        .counter-time {
            font-size: 1.7rem;
        }
    }
</style>

<script>
    function timeSince(when) {
        var obj = {};
        obj._milliseconds = when.valueOf() - (new Date()).valueOf();
        obj.milliseconds = obj._milliseconds % 1000;
        obj._seconds = (obj._milliseconds - obj.milliseconds) / 1000;
        obj.seconds = obj._seconds % 60;
        obj._minutes = (obj._seconds - obj.seconds) / 60;
        obj.minutes = obj._minutes % 60;
        obj._hours = (obj._minutes - obj.minutes) / 60;
        obj.hours = obj._hours % 24;
        obj._days = (obj._hours - obj.hours) / 24;
        obj.days = obj._days % 365;
        return obj;
    }

    function splitNumber(num) {
        var obj = [];
        if (num < 10) {
            obj[0] = '0';
            if (num <= 0) {
                obj[1] = 0;
            } else {
                obj[1] = num;
            }
        } else {
            obj[0] = Math.floor(num / 10);
            obj[1] = num - obj[0] * 10;
        }
        return obj;
    }
    export default {
        props: [
            "start"
        ],
        data() {
            return {
                startDate: null,
                timer: null,
                live: false,
                d1: '&nbsp', d2: '&nbsp', h1: '&nbsp', h2: '&nbsp', m1: '&nbsp', m2: '&nbsp', s1: '&nbsp', s2: '&nbsp'
            }
        },
        mounted() {
            this.startDate = new Date(this.start);
            this.startCounter();
        },
        methods: {
            startCounter() {
                let app = this;
                this.timer = setInterval(function () {

                    var td = timeSince(app.startDate);
                    var days = splitNumber(td.days);
                    app.d1 = days[0];
                    app.d2 = days[1];

                    var hours = splitNumber(td.hours);
                    app.h1 = hours[0];
                    app.h2 = hours[1];

                    var minutes = splitNumber(td.minutes);
                    app.m1 = minutes[0];
                    app.m2 = minutes[1];

                    var seconds = splitNumber(td.seconds);
                    app.s1 = seconds[0];
                    app.s2 = seconds[1];

                    //if (app.d1 + app.d2 + app.h1 + app.h2 + app.m1 + app.m2 + app.s1 + app.s2 == 0) {
                    //    clearInterval(app.timer);
                    //    app.live = true;
                    //}

                }, 1000)
            }
        },
    }
</script>
