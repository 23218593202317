<template>
    <div class="card-body text-center fs-5">
        <h3 class="mb-2 mb-sm-3 px-2 px-sm-0">Записи трансляций розыгрышей:</h3>
        <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-interval="false">
            <div class="carousel-inner w-100">
                <div class="row m-0">
                    <div class="col-1 p-0 d-flex justify-content-center">
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                    </div>
                    <div class="col-10 px-1">
                        <div class="carousel-item active" data-bs-interval="100000">
                            <div class="row m-0">
                                <div class="col-6 text-center  pe-1 pe-sm-2 ps-0">
                                    <iframe class="video-tr img-fluid" src="https://www.youtube.com/embed/4gft25oOgcw" title="Розыгрыш 14.12.2022 'ВЗЛОМАЙ УДАЧУ'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <br />
                                    <span class="carousel-title">Розыгрыш 14.12.2022</span>
                                </div>
                                <div class="col-6 text-center  pe-1 pe-sm-2 ps-0">
                                    <iframe class="video-tr img-fluid" src="https://www.youtube.com/embed/531Bf6WgMps" title="Розыгрыш 21.11.2022 'ВЗЛОМАЙ УДАЧУ'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <br />
                                    <span class="carousel-title">Розыгрыш 21.11.2022</span>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item" data-bs-interval="2000">
                            <div class="row m-0">
                                <div class="col-6 text-center  pe-1 pe-sm-2 ps-0">
                                    <iframe class="video-tr img-fluid" src="https://www.youtube.com/embed/5cVTLaRWL54" title="Розыгрыш 07.11.2022 'ВЗЛОМАЙ УДАЧУ'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <br />
                                    <span class="carousel-title">Розыгрыш 07.11.2022</span>
                                </div>
                                <div class="col-6 text-center  pe-1 pe-sm-2 ps-0">
                                    <iframe class="video-tr img-fluid" src="https://www.youtube.com/embed/_nJyC_NbREI" title="Розыгрыш 24.10.2022 'ВЗЛОМАЙ УДАЧУ'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <br />
                                    <span class="carousel-title">Розыгрыш 24.10.2022</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 p-0 d-flex justify-content-center">
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .carousel-control-prev, .carousel-control-next {
        position: inherit;
        width: auto;
    }

    .carousel-indicators {
        position: inherit;
        margin: 0;
        bottom: 0;
    }

    .carousel-title {
        font-size: 1rem;
        line-height: 3px;
    }
</style>

