
    //import CodePanel from './components/CodePanel.vue'
    //import FioPanel from "./components/FioPanel.vue";
    import WinnersPanel from "./components/WinnersPanel.vue";
    /*import CounterPanel from './components/CounterPanel.vue'*/
    import TranslationsPanel from './components/TranslationsPanel.vue'


    import { defineComponent } from 'vue';

    const uri_code_regexp = new RegExp('^(\\d+)[^\\d]*?', 'g');

    export default defineComponent({
        name: 'TestApp',
        props: ['qrcode'],
        components: {
            //CodePanel,
            //FioPanel,
            /*CounterPanel,*/
            WinnersPanel,
            TranslationsPanel
        },
        data() {
            return {
                code: '',
                codeStage: true,
                ticket: null,
                recaptcha_token: null,
                first: true,
                anchor: false
            }
        },
        created() {
            let uri = window.location.href.split('/');
            let code = uri[uri.length - 1];
            let matches = code.matchAll(uri_code_regexp);
            let match = matches.next();
            if (match.value && match.value.length > 0) {
                this.code = match.value[1];
                this.anchor = window.location.href.indexOf('#') != -1;
            }
        },
        methods: {
            setTicket(event: any) {
                this.ticket = event.ticket;
                this.recaptcha_token = event.recaptcha_token;
                this.codeStage = false;
            },
            dataPosted() {
                this.first = false;
                this.codeStage = true;
            },
        }
    });
