<template>
    <div class="card-body text-center" id="winners-panel">
        <h3 class="mb-2 mb-sm-3" id="winners">Призеры розыгрышей</h3>
        <div v-if="winners.length">
            <ul class="nav nav-pills justify-content-between">
                <li v-for="drawing, index0 in drawings" :key="drawing.id" :class="{'nav-item': true, 'disabled': !drawing.active, 'mb-1': true}">
                    <a :class="{'nav-link': true, 'active': true, 'bg-white': drawing.current, 'text-primary':  drawing.current }" @click.prevent="changeDrawing(index0)" aria-current="page" :href="'#' + drawing.id">{{ drawing.name }}</a>
                </li>
            </ul>
            <div class="tab-content mt-2 mt-sm-3">
                <div v-for="drawing1, d_index in getActiveDrawings()" :key="drawing1.id" :id="drawing1.id" :class="{'tab-pane' : true, 'active' : drawing1.current}">
                    <div class="accordion" :id="'accordion' + drawing1.id">
                        <div v-for="city, index in cities" :key="index">
                            <div v-if="getPrizesCount(d_index, index)" class="accordion-item">
                                <h2 class="accordion-header" :id="'city_header' + index">
                                    <button :class="{'accordion-button':true, 'collapsed': !isActiveCity(index)}" type="button" @click="changeActiveCity(index)" data-bs-toggle="collapse" :data-bs-target="'#city_content' + index + drawing1.id" :aria-expanded="isActiveCity(index)" aria-controls="collapseOne">
                                        <div class="container p-0 row">
                                            <div class="col-2 col-md-1 text-center">
                                                <span class="badge bg-primary">{{ getPrizesCount(d_index, index) }}</span>
                                            </div>
                                            <div class="col-10 col-md-11 px-1">{{ city }}</div>
                                        </div>
                                    </button>
                                </h2>
                                <div :id="'city_content' + index + drawing1.id" :class="{'accordion-collapse': true, 'collapse': true, 'show': isActiveCity(index)}" aria-labelledby="headingOne" :data-bs-parent="'#accordion' + drawing1.id">
                                    <div class="accordion-body">
                                        <div v-for="prize, index1 in prizes" :key="index1">
                                            <h5>{{ prize.name }}</h5>
                                            <div class="table-responsive">
                                                <table class="table text-start">
                                                    <tbody v-if="winners[d_index].cities[index].prizes[index1].winners.length">
                                                        <tr v-for="winner, index2 in winners[d_index].cities[index].prizes[index1].winners" :key="index2">
                                                            <td class="col-10">{{ winner.name }}</td>
                                                            <td class="col-2">{{ winner.tel }}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr class="text-center">
                                                            <td>Нет призеров</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            Загрузка...
        </div>
    </div>
</template>

<style scoped>
    .nav-item.disabled {
        opacity: 0.5;
    }

    .prize-count {
        width: 3rem;
    }

    .table > :not(caption) > *:last-child > * {
        border-bottom-width: 0;
    }
</style>

<script>
    const drawings = [
        {
            id: "draw2410",
            name: "24.10.2022",
            active: true,
            current: false
        },
        {
            id: "draw0711",
            name: "07.11.2022",
            active: true,
            current: false

        },
        {
            id: "draw2111",
            name: "21.11.2022",
            active: true,
            current: false,
        },
        {
            id: "draw1412",
            name: "14.12.2022",
            active: true,
            current: true,
        }
    ];
    const cities = ["Киров, Кирово-Чепецк, Слободской", "Казань, Зеленодольск", "Нижнекамск", "Набережные Челны", "Альметьевск", "Йошкар-Ола", "Чебоксары, Новочебоксарск", "Глазов", "Ижевск", "Сыктывкар", "Онлайн-покупатели"];
    const prizes = [
        {
            name: "Подарочный сертификат сети салонов «Топаз», «Яхонт» и «Драгоцентр» номиналом 4000 рублей",
            count: 640
        }, {
            name: "Золотые ювелирные украшения",
            count: 271
        },
        {
            name: "Украшение с бриллиантами"
        },
        {
            name: "Золотой слиток"
        },
        {
            name: "Эксклюзив"
        }
    ];

    const is_dev = process.env.NODE_ENV == 'development';
    const endpoint = is_dev ? 'https://localhost:5001/codes/' : 'https://yahont.promo.support/codes/';

    export default {

        props: [
            "start"
        ],
        data() {
            return {
                cities: cities,
                drawings: drawings,
                prizes: prizes,
                winners: [],
                active_city_index: null,
                endpoint: endpoint
            }
        },
        mounted() {
            //const accordionItems = document.querySelectorAll('.accordion-collapse')
            //const accs = document.querySelectorAll('.accordion')

            //accs.forEach((acc) => {
            //    accordionItems.forEach((el) => {
            //        el.addEventListener('shown.bs.collapse', () => {
            //            var scrollOffset = acc.scrollTop + el.parentNode.offsetTop
            //            acc.scroll({
            //                top: scrollOffset,
            //                left: 0,
            //                behavior: 'smooth'
            //            })
            //        })
            //    })
            //})
        },
        created() {
            let app = this;
            let url = this.endpoint + 'winners';
            fetch(url).then((result) => { return result.json(); }).then((data) => {
                app.winners = data.data;
            });
        },
        methods: {
            getActiveDrawings() {
                return this.drawings.filter((d) => d.active);
            },
            getPrizesCount(d_index, index) {
                if (this.winners.length) {
                    return this.winners[d_index].cities[index].prizes[0].winners.length +
                        this.winners[d_index].cities[index].prizes[1].winners.length +
                        this.winners[d_index].cities[index].prizes[2].winners.length +
                        this.winners[d_index].cities[index].prizes[3].winners.length +
                        this.winners[d_index].cities[index].prizes[4].winners.length;
                        
                } else {
                    return 0;
                }
            },
            getWinners(d_index, index, index1) {
                if (this.winners.length) {
                    return this.winners[d_index].cities[index].prizes[index1].winners;
                } else {
                    return [];
                }
            },
            changeDrawing(index0) {
                if (this.drawings[index0].active) {
                    let current_drawing = this.drawings.find((d) => d.current);
                    current_drawing.current = false;
                    this.drawings[index0].current = true;
                }
            },
            changeActiveCity(index) {
                if (this.active_city_index === index) {
                    this.active_city_index = null;
                } else {
                    this.active_city_index = index;
                }
            },
            isActiveCity(index) {
                return this.active_city_index === index;
            }
        }
    }
</script>
