<template>
    <form autocomplete="on" class="my-2">
        <h5 class="text-light mb-3">Ваш код принят. Заполните, пожалуйста, форму для связи с вами в случае выигрыша:</h5>
        <div class="form-floating form-floating-sm mb-3">
            <input type="text" class="form-control" id="name" name="name" v-model="name" autocomplete="name" :disabled="form_disabled" />
            <label for="name">ФИО *</label>
            <span v-if="v$.name.$error" class="form-text text-danger">
                Нам нужно знать, как к вам обращаться
            </span>
        </div>
        <div class="form-floating form-floating-long form-floating-sm mb-4">
            <input type="text" class="form-control" id="tel" name="tel" v-model="tel" autocomplete="tel" :disabled="form_disabled" />
            <label for="tel">Телефон *</label>
            <span v-if="v$.$dirty && v$.tel.required.$invalid" class="form-text text-danger">
                Нам нужно знать, как с вами связаться и куда начислить выигрыш
            </span>
            <span v-if="v$.$dirty && v$.tel.tel_is_valid.$invalid" class="form-text text-danger">
                Формат номера телефона +7XXXXXXXXXX
            </span>
        </div>
        <div class="form-floating form-floating-sm mb-3">
            <input type="text" class="form-control" id="email" name="email" v-model="email" autocomplete="email" :disabled="form_disabled" />
            <label for="name">Email</label>
            <span v-if="v$.email.$error" class="form-text text-danger">
                Некорректная электронная почта
            </span>
        </div>
        <div class="form-check text-start">
            <input class="form-check-input" type="checkbox" value="true" v-model="rules" id="rules">
            <label class="form-check-label text-light" for="rules">
                Я согласен(на) с <a class="text-light" href="/files/rules.pdf" target="_blank">правилами акции</a>
            </label>
            <div v-if="v$.rules.$error" class="form-text text-danger">
                Необходимо выразить согласие
            </div>
        </div>
        <div class="form-check text-start mb-3">
            <input class="form-check-input" type="checkbox" value="true" v-model="private" id="private">
            <label class="form-check-label text-light" for="age">
                Я согласен(на) на обработку персональных данных
            </label>
            <div v-if="v$.private.$error" class="form-text text-danger">
                Необходимо выразить согласие
            </div>
        </div>
        <div class="raw col-12 text-center">
            <action-button ref="sendPostDataButton" @click.prevent="sendPostData" :disabled="form_disabled">
                ОТПРАВИТЬ
            </action-button>
        </div>

        <modal-box ref="modalBox1" @onclose="modalClose">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h4>
                    {{ modalMsg }}
                </h4>
                <span v-html="modalDescr"></span>
            </div>
        </modal-box>
    </form>
</template>

<script>

    import useVuelidate from '@vuelidate/core'
    import { required, minLength, maxLength, helpers, email } from '@vuelidate/validators'
    import ActionButton from "./ActionButton.vue";
    import ModalBox from "./ModalBox.vue";
    import axios from 'axios';


    //const tel_regexp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d- ]{7,10}$/;
    const tel_regexp = /^\+7\d{10}$/;
    const tel_is_valid = (value) => !helpers.req(value) || value.match(tel_regexp);
    const is_dev = process.env.NODE_ENV == 'development';
    const endpoint = is_dev ? 'https://localhost:5001/codes/' : 'https://yahont.promo.support/codes/';
    const telBegin = "+7";

    export default {
        props: ["ticket", "recaptcha_token"],
        name: 'FioBox',
        components: {
            'action-button': ActionButton,
            'modal-box': ModalBox
        },
        setup() {
            let v = useVuelidate(undefined, undefined, { $scope: false });
            return { v$: v }
        },
        data() {
            return {
                name: null,
                tel: telBegin,
                email: '',
                rules: null,
                private: null,
                endpoint: endpoint,
                modalMsg: undefined,
                modalDescr: undefined,
                form_disabled: false,
                recaptcha_token_: this.recaptcha_token,
            }
        },
        validations() {
            return {
                name: { required, minLength: minLength(1), maxLength: maxLength(64) },
                tel: { required, tel_is_valid },
                email: { email },
                rules: { required },
                private: { required },
                recaptcha_token_: { valid: required },
            }
        },
        methods: {
            created() {
                this.recaptcha_token_ = this.recaptcha_token;
            },
            modalClose() {
                this.$emit("dataposted");
            },
            sendPostData() {

                this.v$.$touch();

                if (this.v$.$invalid) {
                    return;
                }

                this.$refs.sendPostDataButton.start();

                let formData = new FormData();

                formData.append('name', this.name);
                formData.append('tel', this.tel);
                formData.append('email', this.email);
                formData.append('codeRequest', this.ticket);
                formData.append('recaptchaToken', this.recaptcha_token_);

                let app = this;

                this.form_disabled = true;

                axios(
                    {
                        method: 'post',
                        url: endpoint + 'postData',
                        data: formData,
                        //timeout: 10000,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        }
                    }
                )
                    .then(response => {
                        app.modalMsg = response.data.message;
                        app.modalDescr = response.data.description;
                        app.$refs.modalBox1.open();
                        app.v$.$reset();
                        app.$refs.sendPostDataButton.stop();
                        app.form_disabled = false;
                    })
                    .catch(data => {
                        let response = data.response;
                        if (data.code == "ERR_NETWORK") {
                            app.modalMsg = "🤕 Нет соединения с интернетом или сервис недоступен";
                            app.modalDescr = "Проверьте соединение и повторите попытку";
                        } else if (data.code == "ECONNABORTED") {
                            app.modalMsg = "⏰ Таймаут соединения";
                            app.modalDescr = "Вероятно, сервер сильно загружен. Повторите позже";
                        }
                        else if (response.data) {
                            app.modalMsg = response.data.message;
                            app.modalDescr = response.data.description;
                        }
                        else {
                            app.modalMsg = "Ошибка " + response.status;
                            app.modalDescr = "";
                        }
                        app.$refs.modalBox.open();
                        app.$refs.sendPostDataButton.stop();
                        app.form_disabled = false;
                    })
                  
            }
        }
    }
</script>

<style scoped>
    img.store-img {
        max-width: 60%;
        cursor: pointer;
    }

    .wb-receipt {
        background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2220%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0%2017.6c0%201.05.806%201.9%201.8%201.9h12.073L18%2015.143V2.4c0-1.05-.806-1.9-1.8-1.9H1.8C.806.5%200%201.35%200%202.4v15.2zM1.8%202.4h14.4v10.45h-2.7c-.994%200-1.8.85-1.8%201.9v2.85H1.8V2.4zm11.7%2012.35h2.326L13.5%2017.206V14.75zm-9-1.9v1.9h5.4v-1.9H4.5zm0-1.9v-1.9h9v1.9h-9zm0-5.7v1.9h9v-1.9h-9z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        font-size: 0;
        line-height: 1;
        display: inline-block;
    }

    .break {
        flex-basis: 100%;
        height: 0;
    }

    .modal-body {
        padding-bottom: 2rem;
    }

    @media (max-width: 386px) {
        .form-floating-long > .form-control:not(:placeholder-shown) {
            padding-top: 3rem;
        }

        .form-floating-long > .form-control {
            height: calc(4.9rem + 2px);
        }

        .form-floating-long > label {
            text-align: left;
        }
    }
</style>
