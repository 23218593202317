import './assets/css/bootstrap.scss'
import './assets/css/fontello.css'
import './assets/css/style.css'

import { createApp } from 'vue'
import { createWebHistory, createRouter } from 'vue-router';

import App from './App.vue';

import MainApp from './MainApp.vue';
import TestApp from './TestApp.vue';


const routes = [
	{ path: '/:usercode?', component: MainApp },
	{ path: '/test/', component: TestApp }
]

const router = createRouter({
	history: createWebHistory(),
	routes,
});

createApp(App).use(router).mount('#app')
