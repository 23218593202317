<template>
    <form autocomplete="on" class="my-2">
        <div class="form-floating form-floating-sm mb-3">
            <input type="text" class="form-control" id="code" name="code" v-model="code" autocomplete="off" :disabled="form_disabled" />
            <label for="code">Код *</label>
            <span v-if="v$.$dirty && v$.code.required.$invalid" class="form-text text-danger">
                Поле 'Код' обязательно для заполнения
            </span>
            <span v-if="v$.$dirty && !v$.code.required.$invalid && v$.code.is_code.$invalid" class="form-text text-danger">
                Некорректное значение поля 'Код'
            </span>
            <span v-if="v$.code.maxLength.$invalid" class="form-text text-danger">
                Некорректное значение поля 'Код' (от 1 до 32 символов)
            </span>
        </div>
        <div class="mb-4 d-flex justify-content-center" style="flex-wrap: wrap">
            <div id="g-recaptcha-container"></div>
            <div v-if="v$.recaptcha_token.$error && recaptcha_loaded" class="form-text text-danger w-100 text-center">
                Подтвердите, что вы не робот
            </div>
            <div v-if="v$.recaptcha_loaded.$error" class="form-text text-danger w-100 text-center">
                Дождитесь загрузки виджета "Я не робот" или перезагрузите страницу
            </div>
        </div>
        <div class="raw col-12 text-center">
            <action-button ref="sendCodeButton" @click.prevent="sendCode" :disabled="form_disabled">
                ЗАРЕГИСТРИРОВАТЬ
            </action-button>
        </div>

        <modal-box ref="modalBox">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h4>
                    {{ modalMsg }}
                </h4>
                <span v-html="modalDescr"></span>
            </div>
        </modal-box>
    </form>
</template>

<script>
    /* global grecaptcha:false */

    import useVuelidate from '@vuelidate/core'
    import { required, maxLength } from '@vuelidate/validators'
    import ActionButton from "./ActionButton.vue";
    import ModalBox from "./ModalBox.vue";
    import axios from 'axios';

    const is_dev = process.env.NODE_ENV == 'development';
    const is_code = (value) => /^\d+$/.test(value);
    const endpoint = is_dev ? 'https://localhost:5001/codes/' : 'https://yahont.promo.support/codes/';

    export default {
        props: ["qrcode", "first"],
        name: 'CodePanel',
        components: {
            'action-button': ActionButton,
            'modal-box': ModalBox
        },
        setup() {
            let v = useVuelidate(undefined, undefined, { $scope: false });
            return { v$: v }
        },
        created() {
            if (this.qrcode !== '' && this.first) {
                this.code = this.qrcode;
            }
            let app = this;
            let interval = setInterval(() => {
                if (window["recaptcha_loaded"] && !app.recaptcha_inited) {
                    if (grecaptcha) {
                        grecaptcha.render("g-recaptcha-container", {
                            "sitekey": "6Lf4iIMgAAAAAPss9yl5yYkiZ3y7IIzotyjoBq5v"
                        });
                        app.recaptcha_inited = true;
                        clearInterval(interval);
                        app.recaptcha_loaded = true;
                    }
                }
            }, 500);
        },
        data() {
            return {
                code: null,
                endpoint: endpoint,
                modalMsg: undefined,
                modalDescr: undefined,
                recaptcha_inited: false,
                recaptcha_loaded: null,
                recaptcha_token: null,
                first_use: true,
                form_disabled: false
            }
        },
        validations() {
            return {
                code: { required, maxLength: maxLength(32), is_code },
                recaptcha_token: { valid: required },
                recaptcha_loaded: { required }
            }
        },
        methods: {
            sendCode() {
                if (!this.recaptcha_loaded === null) {
                    this.v$.$touch();
                    return;
                }

                this.recaptcha_token = grecaptcha.getResponse();

                this.v$.$touch();

                if (this.v$.$invalid) {
                    return;
                }

                this.$refs.sendCodeButton.start();

                let formData = new FormData();

                formData.append('code', this.code);
                formData.append('recaptchaToken', this.recaptcha_token);

                let app = this;

                this.form_disabled = true;

                axios(
                    {
                        method: 'post',
                        url: endpoint + 'check',
                        data: formData,
                        //timeout: 10000,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        }
                    }
                )
                    .then(response => {

                        let rdata = response.data;
                        let data = (rdata === null) ? null : rdata.data;
                        if (data && data.ticket) {
                            app.code = null;
                            grecaptcha.reset();
                            app.v$.$reset();
                            app.$emit('ticket', { ticket: data.ticket, recaptcha_token: app.recaptcha_token });
                            app.$refs.sendCodeButton.stop();
                            app.form_disabled = false;
                            return;
                        }
                        app.modalMsg = rdata.message;
                        app.modalDescr = rdata.description;
                        app.$refs.sendCodeButton.stop();
                        app.$refs.modalBox.open();
                        app.v$.$reset();
                        grecaptcha.reset();
                        app.code = null;
                        app.form_disabled = false;
                    })
                    .catch(data => {
                        let response = data.response;
                        if (data.code == "ERR_NETWORK") {
                            app.modalMsg = "🤕 Нет соединения с интернетом или сервис недоступен";
                            app.modalDescr = "Проверьте соединение и повторите попытку";
                        } else if (data.code == "ECONNABORTED") {
                            app.modalMsg = "⏰ Таймаут соединения";
                            app.modalDescr = "Вероятно, сервер сильно загружен. Повторите позже";
                        }
                        else if (response.data) {
                            app.modalMsg = response.data.message;
                            app.modalDescr = response.data.description;
                        }
                        else {
                            app.modalMsg = "Ошибка " + response.status;
                            app.modalDescr = "";
                        }
                        app.$refs.modalBox.open();
                        app.$refs.sendCodeButton.stop();
                        app.form_disabled = false;

                    });
            }
        }
    }
</script>

<style scoped>
    img.store-img {
        max-width: 60%;
        cursor: pointer;
    }

    .wb-receipt {
        background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2220%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0%2017.6c0%201.05.806%201.9%201.8%201.9h12.073L18%2015.143V2.4c0-1.05-.806-1.9-1.8-1.9H1.8C.806.5%200%201.35%200%202.4v15.2zM1.8%202.4h14.4v10.45h-2.7c-.994%200-1.8.85-1.8%201.9v2.85H1.8V2.4zm11.7%2012.35h2.326L13.5%2017.206V14.75zm-9-1.9v1.9h5.4v-1.9H4.5zm0-1.9v-1.9h9v1.9h-9zm0-5.7v1.9h9v-1.9h-9z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        font-size: 0;
        line-height: 1;
        display: inline-block;
    }

    .break {
        flex-basis: 100%;
        height: 0;
    }

    .modal-body {
        padding-bottom: 2rem;
    }

    @media (max-width: 386px) {
        .form-floating-long > .form-control:not(:placeholder-shown) {
            padding-top: 3rem;
        }

        .form-floating-long > .form-control {
            height: calc(4.9rem + 2px);
        }

        .form-floating-long > label {
            text-align: left;
        }
    }
</style>
